import React, {useEffect, useState} from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import moment from 'moment';
import MonthView from './MonthView';
import useDebounce from 'react-use/lib/useDebounce';
import CustomersTable from './CustomersTable';
import axios from 'axios';
import {API_PREFIX, GENIAM_API} from '../../../apis/new_urls';
import _ from 'lodash';
import {useGlobal} from 'reactn';
import '../../ConnectStripe/styles.css';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function Customers() {
    const [open, setOpen] = React.useState(false);
    const [month, setMonth] = useState(moment());
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [metamorInfo, setMetamorInfo] = useState({})
    const [connectInfo, setConnectInfo] = useState({})
    const [user] = useGlobal('user')

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    const getCustomers = async (month) => {
        try {
            setLoading(true)
            // const start = moment(month).startOf("month").utc().format()
            // const end = moment(month).endOf("month").utc().format()
            const getmonth = moment(month).format('YYYY-MM')
            const {data} = await axios.get(API_PREFIX + `/affiliate?month=${getmonth}`)
            setData(_.orderBy(data, "time", "desc"))
        } catch (e) {
            console.log(e);
        } finally {
            setLoading(false)
        }

    }
    const getMetamorInfo = async () => {
        try {
            const {data} = await axios.get(API_PREFIX + `/geniam/affiliate/info`)
            setMetamorInfo(data)
            getConnectInfo()
            console.log(data);
        } catch (e) {

        }
    }
    const getConnectInfo = async () => {
        setLoading(true)
        try {
            let {data} = await axios.get(GENIAM_API + `/v1/connects/get-connectByUser?uuid=${user.metamors_uuid}`)
            if (data) {
                setConnectInfo(data)
            }
        } catch (e) {
            // toast.error(e.toString())
            console.log(e.toString())
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        if (open)
            getCustomers(month)
        // eslint-disable-next-line
    }, [open])
    useEffect(() => {
        getMetamorInfo()
        // eslint-disable-next-line
    }, [])
    useDebounce(
        () => {
            getCustomers(month)
        },
        500,
        [month]
    )
    const handleClickView = () => {
        window.open(connectInfo.stripe_url_view.url, "_blank")
    }

    return (
        <div style={{marginTop: 20}}>
            <Button variant="outlined" color="primary" onClick={handleClickOpen} style={{marginRight: 30}}>
                My Customers
            </Button>

            {
                connectInfo.metamor_uuid ?
                    <button onClick={handleClickView}
                            className="stripe-connect"

                    >
                        <span>View Connect Information</span>
                    </button>
                    :
                    null
            }
            <Dialog
                open={open}
                TransitionComponent={Transition}
                onClose={handleClose}
                maxWidth={"lg"}

            >
                <DialogTitle>{"Afffiliate Customers "}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <MonthView loading={loading} month={month} setMonth={setMonth} metamor={metamorInfo}/>
                        <CustomersTable data={data} loading={loading}/>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
