import {Cals} from "../components/Cal/initCals";

export const refetchEvents = () => {
    Object.keys(Cals).map(key => {
        const calApi = Cals[key]
        if (calApi && typeof calApi.refetchEvents === "function") {
            calApi.refetchEvents()
        }
    })
}
