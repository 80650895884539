import {API_PREFIX, USER_INFO} from "./new_urls";
import axios from 'axios'
import {USER_INFO_KEY} from "./constants";
import store from 'store'
import _ from 'lodash'
import {setGlobal} from 'reactn'


export async function getUserInfoAsync(force = false, cancelToken) {
    const localUserInfo = store.get(USER_INFO_KEY);
    if (!force && localUserInfo && _.isPlainObject(localUserInfo)) {
        setGlobal({user: localUserInfo})
        return localUserInfo;
    }
    const res = await axios.get(USER_INFO, cancelToken);
    if (res) {
        const user = res.data
        user.skills.forEach(s => {
            s.pivot.selected = 1
        })
        store.set(USER_INFO_KEY, user);
        setGlobal({
            allCodes: (user.skills || []).map(s => s.code),
            user
        })
        return res.data;
    }
    return {}
}

export async function updateUserInfoAsync(data) {
    return await axios.post(USER_INFO, data)
}


export const getMetamorInfo = async () => {
    try {
        const {data} = await axios.get(API_PREFIX + '/info');
        if (data) {
            setGlobal({
                user: {...data, ...data?.profile}
            });
            localStorage.setItem(USER_INFO_KEY, JSON.stringify({...data, ...data?.profile}));
        }
    } catch (e) {
        console.log(e.toString());
    }
};
