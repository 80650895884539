import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import configAxios from './config/axios'
import {unregister as SWUnregister} from './registerServiceWorker'
import {BrowserRouter} from "react-router-dom";

import './globalData'
import GoogleAuth from './components/Cal/google/auth'

configAxios();
GoogleAuth();
ReactDOM.render((
    //{/*<HotKeys keyMap={keyMaps} handlers={keyMapsHandlers}>*/}
    <BrowserRouter>
        <App/>
    </BrowserRouter>
    //{/*</HotKeys>*/}
), document.getElementById('root'));

// console.log(process.env);
// registerServiceWorker();

SWUnregister()
