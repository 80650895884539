import qs from 'query-string'
import axios from 'axios'
import {API_PREFIX} from "../apis/new_urls";
import {toast} from "react-toastify";

export const linkedAccount = async () => {
    const query = qs.parse(window.location.search)
    console.log('query', query);
    if (query.uuid) {
        try {
            const {data} = await axios.post(API_PREFIX + "/saveGeniamUuid", {
                geniam_uuid: query.uuid
            })
            if (data) {
                window.location = '/'
            }
        } catch (e) {
            console.log(e);
            toast.error(" Genniam Account Link fail. Try again!ß")
        }
    }
}