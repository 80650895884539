import {setGlobal} from 'reactn'

export default function GoogleAuth() {

    const src = "https://apis.google.com/js/platform.js"
    let script = document.createElement('script')
    script.src = src
    script.async = true
    script.addEventListener('load', onScriptLoad)
    script.addEventListener('error', onScriptError)
    document.body.appendChild(script)


}

function onScriptError() {
    console.log('error !!!')
}

function onScriptLoad() {
    window.gapi.load('client:auth2', function () {
        window.gapi.client.load('calendar', 'v3', function () {
            setGlobal({
                ggCalInit: true
            })
        })
    })
}


