import React from "react";
import {CircularProgress} from '@material-ui/core';
import Fade from "@material-ui/core/Fade/Fade";
import {useGlobal} from 'reactn'


const loadStyle = {
    position: "absolute",
    top: "0px",
    right: "0px",
    bottom: "0px",
    left: "0px",
    margin: "auto",
    width: "50px",
    height: "50px",
}

function Loading() {

    const [loading] = useGlobal('loading')
    return (
        <Fade
            in={loading}
            style={{
                transitionDelay: loading ? '800ms' : '0ms',
            }}
            unmountOnExit
        >
            <div style={loadStyle}><CircularProgress size={50}/></div>
        </Fade>
    )

}

export default (Loading)
