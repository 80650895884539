import React, {useEffect, useState} from 'react';
import MenuAppBar from '../MenuAppBar/MenuAppBar';
import Products from './Products';
import Packages from './Packages';
import Customers from './Customers/index';
import {useGlobal} from 'reactn';
import axios from 'axios';
import {GENIAM_API} from '../../apis/new_urls';
import {LinearProgress, Typography} from '@material-ui/core';
import {Link, useHistory} from 'react-router-dom';

function Affiliate() {
    const [user] = useGlobal('user');
    const [loading, setLoading] = useState(false);
    const [connected, setConnected] = useState(false);
    const history = useHistory();

    useEffect(() => {
        if ((user && !user.isAffiliate)) {
            return history.push('/');
        }
        if (user.metamors_uuid && !connected) {
            getConnectInfo(user.metamors_uuid);
        }
        // eslint-disable-next-line
    }, [user]);
    const getConnectInfo = async user_id => {
        try {
            setLoading(true);
            const {data} = await axios.get(
                GENIAM_API + `/v1/connects/get-connectByUser?uuid=${user_id}`);
            if (data?.stripe_user_id)
                setConnected(true);
        } catch (e) {
            // toast.error(e.toString())
            console.log(e.toString());
            return null;
        } finally {
            setLoading(false);
        }
    };
    return (
        <div>
            <MenuAppBar/>
            <div style={{width: '90%', maxWidth: 1200, margin: 'auto'}}>
                {loading ?
                    <LinearProgress style={{width: '100%', marginTop: 20}}/> :
                    null
                }
                {
                    !loading && connected ?
                        <div>
                            <Customers/>
                            {
                                user.product_affiliate ?
                                    <Products/>
                                    :
                                    null
                            }
                            {
                                user.package_affiliate ?
                                    <Packages/>
                                    :
                                    null
                            }
                        </div>
                        : null
                }

                {
                    !loading && !connected ?
                        <div style={{paddingTop: 20}}>
                            <Typography color={'primary'} variant={'subtitle1'}
                                        component={'h1'}>
                                Affiliate maketing
                            </Typography>
                            <Typography>
                                To enable affiliate must has a Stripe connect
                                account to your bank
                            </Typography>
                            <Typography>
                                Setup connect Stripe account. <Link
                                to={'/stripeConnects'}
                                style={{color: 'red'}}> Click
                                here ! </Link>
                            </Typography>
                        </div>
                        : null
                }
            </div>

        </div>
    );
}

export default Affiliate;
