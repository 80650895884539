import axios from 'axios';
import {ACCESS_TOKEN_KEY} from '../apis/constants';
import _ from 'lodash';

axios.defaults.baseURL = process.env.REACT_APP_API_PREFIX;

export default () => {
    //REQUEST
    axios.interceptors.request.use(
        (config) => {
            console.log(
                `---- ${config.method.toUpperCase()} ${config.url} ----`);
            console.log(config);
            const accessToken = localStorage.getItem(ACCESS_TOKEN_KEY);
            if (accessToken) {
                config.headers['Authorization'] = `Bearer ${accessToken}`;
            }
            return config;
        },
        error => {
            if (error && error.request) {
                console.log(error);
                console.log(error.request);
            }
            return Promise.reject(error);
        });

    //RESPONSE
    axios.interceptors.response.use(
        function (response) {
            return response;
        },
        async function (error) {
            console.log(error.response);
            if (!error.response) {
                return Promise.reject(error);
            }
            const {status} = error.response;

            if (status === 401 && !_.includes(window.location.href, '/login')) {
                if (_.includes(window.location.href, '/register')) {
                    return null
                }
                localStorage.clear();
                return window.location = '/login';
            }
            return Promise.reject(error);
        },
    );
}
