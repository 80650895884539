import React from 'react';
import {IconButton, makeStyles, Typography} from "@material-ui/core";
import SkipPreviousIcon from '@material-ui/icons/SkipPrevious';
import SkipNextIcon from '@material-ui/icons/SkipNext';
import moment from 'moment'
import NumberFormat from '../../../common/currencyFormat'

const useStyles = makeStyles(theme => ({
    button: {
        backgroundImage: 'linear-gradient(#7DC5EE, #008CDD 85%, #30A2E4)',
        display: 'inline-block',
        color: '#fff',
        border: 'solid 1px #bad5f1'
    },
    schedules: {
        display: "inline-block", background: '#1275FF',
        color: '#fff',
        borderRadius: '25px',
        backgroundImage: 'linear-gradient(#7DC5EE, #008CDD 85%, #30A2E4)'
    }
}));

function MonthView({loading, month, setMonth, metamor}) {
    const classes = useStyles()
    const monthTextView = moment(month).format('YYYY-MM')
    const receive = metamor[monthTextView] || 0
    return (
        <div>
            <div className={classes.schedules}>
                <IconButton
                    className={classes.button}
                    disabled={loading}
                    style={{opacity: loading ? '0.5' : '1'}}
                    onClick={() => setMonth(moment(month).subtract(1, "month"))}
                >
                    <SkipPreviousIcon/>
                </IconButton>
                <Typography
                    style={{display: "inline-block", padding: '0 15px'}}>{monthTextView}</Typography>

                <IconButton
                    className={classes.button}
                    onClick={() => setMonth(moment(month).add(1, "month"))}
                    disabled={loading}
                    style={{opacity: loading ? '0.5' : '1'}}
                >
                    <SkipNextIcon/>
                </IconButton>

            </div>
            <div style={{paddingTop: 30, paddingLeft: 30}}>
                <Typography component={"h1"} color={"secondary"}> Transfer {NumberFormat.format(receive)} </Typography>
            </div>
        </div>
    );
}

export default MonthView;