import React, {useEffect, useState} from 'react';
import axios from 'axios';
import {API_PREFIX, CART_URI} from '../../apis/new_urls';
import MaterialTable from 'material-table';
import {toast} from 'react-toastify';
import NumberFormat from '../../common/currencyFormat';
import {Typography} from '@material-ui/core';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import {useGlobal} from 'reactn';

const copy = require('clipboard-copy');

function Packages(props) {
    const [packages, setPackages] = useState([]);
    const [loading, setLoading] = useState(false);
    const [user] = useGlobal('user');
    useEffect(() => {
        getAllPackages()
    }, [])

    const getAllPackages = async () => {
        try {
            setLoading(true)
            const {data} = await axios.get(API_PREFIX + "/admin/affiliate/packages")
            setPackages(data)
        } catch (e) {
            // toast.error(e.toString())
            console.log(e.toString());
        } finally {
            setLoading(false)
        }
    }
    const columns = [
        {
            title: 'Package Name', field: 'packageName', type: 'text',
        },
        {
            title: 'amount', field: 'price',
            render: (row) => NumberFormat.format(row.price)
        },
        {
            title: 'percent', field: 'metamors',
            render: (row) => <Typography>{user?.is_partner ?
                row.partners :
                row.metamors} %</Typography>,
        },

    ]
    const handleCopyCode = async (event, data) => {
        try {
            const link = `${CART_URI}?type=packages&add=${data.id}&num=1&metamor=${user.metamors_uuid}`
            await copy(link)
            return toast.success('Code copied')
        } catch (e) {
            return toast.error('copy link error. Try again')
        }

    }
    return (
        <div style={{marginTop: 20}}>
            <MaterialTable
                options={{
                    actionsColumnIndex: -1,
                    // paging: false,
                    // search: false,
                    // showTitle: false,
                    headerStyle: {
                        backgroundColor: '#3a3a3a',
                        color: '#fff'
                    }
                }}
                isLoading={loading}
                columns={columns}
                data={packages}
                title={" Packages"}
                actions={[
                    {
                        icon: () => <FileCopyIcon/>,
                        tooltip: 'Copy this Link',
                        onClick: (event, data) => handleCopyCode(event, data),
                    },

                ]}
            />
        </div>
    );
}

export default Packages;
