import Loadable from 'react-loadable';
import Loading from './Loading/Loading'

// import React from "react";

function boundLoadable(loader) {
    return Loadable({
        loader: loader,
        loading: Loading,
    });
}

// export const MenuAppBar = boundLoadable(() => import('./MenuAppBar/MenuAppBar'));
export const Index = boundLoadable(() => import('./Index/index'));
export const NoMatch = boundLoadable(() => import('./NoMatch'));
export const Login = boundLoadable(() => import('./Auth/Login'));
export const Calendar = boundLoadable(() => import('./Calendar/Calendar'));
export const Register = boundLoadable(() => import('./Register/Register2'));
export const RegisterInfo = boundLoadable(() => import('./Register/RegisterInfo'));
export const StripeConnects = boundLoadable(() => import('./ConnectStripe/Connects'));
export const Cal = boundLoadable(() => import('./Cal/Cal'));
