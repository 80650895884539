import store from "store";
import moment from "moment";
import axios from 'axios'
import {getGlobal, setGlobal} from 'reactn'

export const ggTokenChecked = async () => {
    try {
        const {user, ggCalInit} = getGlobal()
        if (!ggCalInit || !user?.geniamUser || !user.geniamUser.googleAuth || !user.geniamUser.googleAuth.refreshToken)
            return null
        let ggToken = store.get("ggToken")
        if (!ggToken || !ggToken.exp || moment().add(10, "minutes").isAfter(ggToken.exp) || user.geniamUser.googleAuth.email !== ggToken.email) {
            const tokenRespone = await axios.get(`https://asia-northeast1-geniam-c8d4c.cloudfunctions.net/googleRefreshToken?refreshToken=${user.geniamUser.googleAuth.refreshToken}`)
            ggToken = {
                token: tokenRespone.data.access_token,
                exp: moment().add(1, "hours").utc().format(),
                email: user.geniamUser.googleAuth.email
            };
            store.set("ggToken", ggToken)
        }
        window.gapi.auth.setToken({
            "access_token": ggToken.token
        })
        setGlobal({
            googleCalendarInit: true
        });
        return ggToken
    } catch (e) {
        console.log(e);
        return null
    }

}
