import React from 'react';
import MaterialTable from 'material-table';
import NumberFormat from '../../../common/currencyFormat'
import {Typography} from "@material-ui/core";
import moment from 'moment'

function CustomersTable({data, loading}) {
    const columns = [
        {
            title: 'Email', field: 'email', type: 'text',
        },
        {
            title: 'type', field: 'type', type: 'text',
        },
        {
            title: 'name', field: 'name', type: 'text',
        },
        {
            title: 'number', field: 'num', type: 'text',
            render: row => <Typography>{row.num}</Typography>
        },
        {
            title: 'amount', field: 'amount', type: 'text',
            render: row => <Typography>{NumberFormat.format(row.amount)}</Typography>
        },
        {
            title: 'receive', field: 'received', type: 'text',
            render: row => <Typography>{NumberFormat.format(row.received)}</Typography>
        },
        {
            title: 'time', field: 'time', type: 'text',
            render: row => <Typography>{moment(row.time).local().format('YYYY年MM月DD日 HH:mm')}</Typography>
        },
    ]
    return (
        <div style={{marginTop: 20}}>
            <MaterialTable
                options={{
                    actionsColumnIndex: -1,
                    headerStyle: {
                        backgroundColor: '#3a3a3a',
                        color: '#fff'
                    },
                    paging: false,
                    search: false,
                }}
                isLoading={loading}
                columns={columns}
                data={data}
                title={" Customers"}
            />
        </div>
    );
}

export default CustomersTable;