import React, {useEffect} from 'react';
import {Route, Switch, useHistory} from 'react-router-dom';
import {
    Cal,
    Calendar,
    Index,
    Login,
    NoMatch,
    Register,
    RegisterInfo,
    StripeConnects,
} from './components/LoadableComponents';
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import _ from 'lodash';
import query from './common/query';
import Affiliate from './components/Affiliate';
import {setGlobal, useGlobal} from 'reactn';
import {linkedAccount} from './actions/GeniamAccountLink';
import {ggTokenChecked} from './common/ggToken';
import {refetchEvents} from './common/CalSource';
import qs from 'query-string';
import {getMetamorInfo} from "./apis/user";

/**
 * @return {null}
 */
function App() {
    const [user, setUser] = useGlobal('user');
    const [ggCalInit] = useGlobal('ggCalInit');
    const [googleCalendarInit] = useGlobal('googleCalendarInit');
    const queryParse = qs.parse(window.location.search);
    const accountToken = localStorage.getItem('account_token') || '';
    const history = useHistory();

    useEffect(() => {
        linkedAccount();
        getMetamorInfo();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (Boolean(queryParse?.account_token) || !_.isEmpty(accountToken)) {
            if (queryParse?.account_token) {
                localStorage.setItem('account_token', query('account_token'))
                delete queryParse.account_token;
            }
            history.push(`/register`);
        }
        // eslint-disable-next-line
    }, [accountToken, queryParse?.account_token]);

    useEffect(() => {
        if (ggCalInit) {
            ggTokenChecked();
        }
    }, [user, ggCalInit]);

    useEffect(() => {
        if (!user)
            return null;
        if (googleCalendarInit) {
            window.gapi.client.calendar.calendarList.list().then(({result}) => {
                const data = result.items;
                _.remove(data, g => {
                    return g.id.includes('#contacts@group') || g.accessRole ===
                        'freeBusyReader' || g.id.includes('#holiday@group');
                });
                setGlobal({
                    googleCalendars: data,
                }, () => {
                    refetchEvents();
                });
            }).catch(console.log);
        }
    }, [googleCalendarInit]);

    if (window.location.pathname === '/cal' && !query('service')) {
        window.location.assign('?service=all');
        return null;
    }

    return (
        <div className='App'>
            <Switch>
                <Route exact path='/' component={Index}/>
                <Route exact path="/login" component={Login}/>
                <Route exact path="/register" component={RegisterInfo}/>
                <Route exact path="/calendar" component={Calendar}/>
                <Route exact path="/cal" component={Cal}/>
                <Route exact path="/registers" component={Register}/>
                <Route exact path="/stripeConnects" component={StripeConnects}/>
                <Route exact path="/affiliate" component={Affiliate}/>
                <Route component={NoMatch}/>
            </Switch>
            <ToastContainer
                hideProgressBar={true}
                // closeOnClick={false}
                autoClose={3000}
            />
        </div>
    );
}

export default App;
